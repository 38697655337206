<template>
  <div class="flex flex-col justify-center items-center">
    <HeaderGlobal
      :step="$t('global_step_01_03')"
      :title="$t('global_header_registration_method')"
      titlemenu1=""
      :titlemenu2="$t('global_subscription')"
    />

    <div class="w-11/12 mt-20 text-left font-proximaNormal">
      <div class="w-7/12 flex justify-between">
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-sm">Users</h3>
          <UserIcon width="40" height="40" />
        </div>
        <div class="w-full flex flex-col justify-center items-center">
          <h3 class="mb-6 text-sm"></h3>
          <PointIcon iconColor="#059AA3" />
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-sm">{{$t('global_subscription')}}</h3>
          <SubscriptioIcon />
        </div>
        <div class="w-full flex flex-col justify-center items-center">
          <h3 class="mb-6 text-sm"></h3>
          <PointIcon />
        </div>
        <div class="w-full ml-1 flex flex-col justify-end items-end">
          <h3 class="mb-6 text-sm">Payment</h3>
          <PaymentIcon />
        </div>
      </div>

      <div class="w-6/12 mt-5 flex flex-col justify-center items-center">
        <h3 class="mb-6 text-lg font-proximaMedium">Current Plan</h3>
      </div>
      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md text-blue">Basic Plan</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">(includes 1 user)</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">€ 29.99</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs">/ month</h3>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <hr class="col-start-2 col-span-4 mb-6" />
      </div>
      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md text-blue">Licenses</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <div class="flex justify-start items-start">
            <h3 class="mb-6 mr-2 text-md">4 x</h3>
            <UserIcon />
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">€ 5.00</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs">/ month</h3>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <hr class="col-start-2 col-span-4 mb-6" />
      </div>

      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md text-blue"></h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <div class="flex justify-start items-start">
            <h3 class="mb-6 mr-2 text-md">1 x</h3>
            <UserIcon />
          </div>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">- € 5.00</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs">referral discount</h3>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <hr class="col-start-2 col-span-4 mb-6" />
      </div>
      <div class="w-10/12 flex justify-between font-proximaMedium">
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md text-blue"></h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md text-blue">Monthly Total</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">€44.99</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs"></h3>
        </div>
      </div>

      <div class="w-6/12 mt-5 flex flex-col justify-center items-center">
        <h3 class="mb-6 mt-10 text-lg font-proximaMedium">Add to Plan</h3>
      </div>

      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md text-blue">Data Services</h3>
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md text-blue"></h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">€19.99</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs">/ month</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <button class="buttonSelect">Select</button>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <hr class="col-start-2 col-span-4 mb-6" />
      </div>
      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md text-blue"></h3>
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md text-blue"></h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-md">€199.99</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <h3 class="mb-6 text-xs">/ year</h3>
        </div>
        <div class="w-full flex flex-col justify-start items-start">
          <button class="buttonSelect">Select</button>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <hr class="col-start-2 col-span-4 mb-6" />
      </div>
      <div class="w-10/12 flex justify-between font-proximaNormal">
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md text-blue">Ad Credits</h3>
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <input type="text" id="Credit" class="adCreditsInput" />
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-md">x €4.99</h3>
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <h3 class="mb-6 text-xs">/ unit</h3>
        </div>
        <div class="w-full flex flex-col- justify-start items-start">
          <button class="buttonSelect">Select</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOptions from "../forms/SelectOptions.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import SubscriptioIcon from "../icons/SubscriptioIcon.vue";
import PaymentIcon from "../icons/PaymentIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import PointIcon from "../icons/PointIcon.vue";
import ModalsNewPet from "../modals/ModalsNewPet.vue";

export default {
  components: {
    SelectOptions,
    HeaderGlobal,
    SubscriptioIcon,
    PaymentIcon,
    UserIcon,
    PointIcon,
    ModalsNewPet,
  },
};
</script>

<style lang="scss" scoped>
.selectpets:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  left: -8px;
  top: 40px;
}
.adCreditsInput {
  height: 30px;
  width: 97px;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
.buttonSelect {
  width: 102px;
  height: 36px;
  background: #0ac0cb;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
}
</style>